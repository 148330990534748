<script setup lang="ts">
import type { RecentProduct } from '~/composables/useRecentProducts'

defineProps<{
  product: RecentProduct
}>()

const { t } = useI18n()
</script>

<template>
  <div class="w-full">
    <NuxtLinkLocale
      class="hover:shadow-input-big hover:scale-102 group flex w-full flex-none -translate-y-1 transform cursor-pointer flex-col rounded-lg border bg-white p-2 transition duration-300"
      :to="`/product/${product.id}`"
    >
      <div>
        <img
          v-if="product?.image"
          class="aspect-square w-full rounded object-contain"
          :src="useImageProxy(product?.image, 960, 0)"
          :alt="product.title"
          loading="lazy"
        >
        <img
          v-else
          class="aspect-square w-full rounded bg-whiteobject-contain p-8"
          loading="lazy"
          src="~/assets/images/giardino-logo.svg"
          alt="Giardino logo"
        >
      </div>

      <div class="border-b w-full my-2" />

      <div class="p-2 pt-0 gap-4 flex flex-col">
        <div class="flex flex-col gap-2">
          <div class="min-h-12 md:min-h-14 line-clamp-2 text-base md:text-xl font-bold">
            {{ product.title }}
          </div>
        </div>

        <ColorBar :colors="product.colors" />

        <div class="flex flex-row items-center gap-4">
          <div class="text-sm md:text-base">
            {{ t('product.view_product') }}
          </div>
          <img
            class="group-hover:animate-animated group-hover:animate-infinite group-hover:animate-shake-x !group-hover:animate-duration-5000 group-hover:animate-delay-300 mt-[2px]"
            src="~/assets/images/icons/chevron-right.svg"
            alt="Chevron"
          >
        </div>
      </div>
    </NuxtLinkLocale>
  </div>
</template>
